import React from "react";
import "../styles/modal.css";

const Modal = ({ content }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-body">
          <p>{content}</p>
          <br />
          <h1>1) Chicken Degen Features</h1>
          <img
            src="assets/images/chicken_1.png"
            alt="Chicken Degen Features"
          ></img>
          <h1>2) Tokenomic</h1>
          <img src="assets/chicken/chart_chicken_2.webp" alt="Tokenomic"></img>
          <h1>3) NFTs</h1>
          <img src="assets/images/chicken_2.png" alt="Chicken NFTs"></img>
          <h1>4) Game Hub</h1>
          <img src="assets/images/chicken_3.png" alt="Game Hub"></img>
        </div>
      </div>
    </div>
  );
};

export default Modal;
