import React, { useState } from "react";
import Menu from "../components/Menu";
import Modal from "../components/Modal";
import Loading from "../components/Loading";
import "../styles/white_paper.css";

const eggs = [
  {
    src: "assets/images/egg_1.png",
    description:
      "Upon a hill, where winds did sigh,\nFour hens embarked, with a gleam in their eye.\nA tale they'd heard, of riches untold,\nBuried deep, in days of old.",
  },
  {
    src: "assets/images/egg_2.png",
    description:
      "With scratch and peck, they followed the clues,\nThrough fields of green, and morning dew.\nA map they found, with markings so strange,\nA circle complete, a symbol of change.",
  },
  {
    src: "assets/images/egg_3.png",
    description:
      "Seven suns they toiled, beneath the sky's blaze,\nEight stumbles they met, in a dizzying maze.\nYet on they went, with hearts full of hope,\nFor a treasure they knew, was within their scope.",
  },
  {
    src: "assets/images/egg_4.png",
    description:
      "Through earth they delved, a tunnel they found,\nSecrets revealed, on hallowed ground.\nThere it gleamed, in a chamber deep,\nGold in heaps, where shadows did sleep.",
  },
];

const WhitePaper = () => {
  const [formData, setFormData] = useState({ telegram_id: "", password: "" });
  const [content, setContent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 4) {
        setFormData({ ...formData, [name]: numericValue });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show loading spinner
    fetch(`${process.env.REACT_APP_END_POINT}/api/v1/white-paper`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false); // Hide loading spinner
        if (data.status === false) {
          alert(data.message);
        }
        if (data.status === true) {
          setContent(data.data.content_1);
          setModalOpen(true); // Open modal on success
        }
      })
      .catch((error) => {
        setLoading(false); // Hide loading spinner
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <Menu />
      <div className="white-paper">
        <div className="content">
          <p>
            Welcome to the White Paper, where only the chosen ones can unlock
            the treasure within. You don't need to be a genius, but a little wit
            and calculation might come in handy—don't worry, it's not rocket
            science.
          </p>
          <div className="input-password">
            <form onSubmit={handleSubmit}>
              <div>
                <label>Telegram UserName:</label>
                <input
                  name="telegram_id"
                  onChange={handleChange}
                  placeholder="chickenDEGEn"
                  required
                />
              </div>
              <div>
                <label>Secret Code:</label>
                <input
                  name="password"
                  type="text" // Change input type to text
                  onChange={handleChange}
                  placeholder="1234"
                  maxLength="4" // Hint for maximum length
                  required
                />
              </div>
              <button type="submit" className="button-send">
                Send
              </button>
            </form>
          </div>
          <p>
            The first 100 people to crack the secret code will each receive a
            reward of 100,000 $CKD coins. But wait, there's more! We'll also be
            randomly selecting one lucky codebreaker to win a whopping 1,000,000
            $CKD.
            <br />
            Sounds good? Let the games begin!
          </p>
        </div>
        <div className="egg-container">
          {eggs.map((egg, index) => (
            <div key={index} className="egg">
              <img src={egg.src} alt={`egg ${index + 1}`} />
              <div className="egg-description">{egg.description}</div>
            </div>
          ))}
        </div>
      </div>
      <img src="assets/images/straw.png" alt="straw" className="straw" />
      {loading && <Loading />}
      {modalOpen && (
        <Modal content={content} onClose={() => setModalOpen(false)} />
      )}
    </div>
  );
};

export default WhitePaper;
