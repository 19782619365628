import React from "react";
import "../styles/banner.css";

const RunBanner = () => {
  return (
    <div className="running-text-container">
      <div className="running-text">
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
        ChickenDegen ChickenDegen ChickenDegen ChickenDegen ChickenDegen
      </div>
    </div>
  );
};

export default RunBanner;
