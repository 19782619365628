import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../styles/fallingcoin.css";

const FallingCoins = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/CKDAirdrop") {
      const numCoins = 5; // Number of coins
      const duration = 3; // Duration of falling animation (seconds)

      for (let i = 0; i < numCoins; i++) {
        createCoin();
      }

      function createCoin() {
        const coin = document.createElement("div");
        coin.className = "coin";
        coin.style.left = Math.random() * 100 + "vw";
        coin.style.animationDuration = Math.random() * duration + 2 + "s";
        document.body.appendChild(coin);
        coin.addEventListener("animationend", () => {
          document.body.removeChild(coin);
          createCoin();
        });
      }
    }
  }, [location]);

  if (location.pathname !== "/CKDAirdrop") {
    return null;
  }

  return <div className="falling-coins"></div>;
};

export default FallingCoins;
