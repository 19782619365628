import React from "react";
import Menu from "../components/Menu";
import Social from "../components/Social";
import FallingCoins from "../components/FallingCoins";
import "../styles/chicken.css";

const Chicken = () => {
  return (
    <div className="containe-home">
      <Menu />
      <FallingCoins />{" "}
      <div className="banner-chicken">
        <img
          className="img_banner"
          src="/assets/chicken/banner_chicken.gif"
          alt="banner chicken"
        />
      </div>
      <div className="ca-chicken">
        <h1>$CKD Airdrop</h1>
        <p className="text-chicken">
          Official information about $CKD will be revealed soon at the most
          opportune time. The chickens seem to know something about the $CKD
          Airdrop, and here is the poem they keep whispering to each other.
        </p>
        <div className="ca-content">
          <img src="/assets/chicken/ca1.gif" alt="CKD Airdrop gif" />
          <div className="ca-text">
            <p>
              Chicken Degen, a game so fun, <br />
              $CKD tokens, the race to run. <br />
              Tap and earn, the more you play, <br />
              Airdrop awaits, a glorious day.
              <br />
            </p>
          </div>
          <img src="/assets/chicken/ca2.gif" alt="CKD Airdrop gif" />
        </div>
      </div>
      {/* <div className="chart-chicken">
        <img src="/assets/chicken/chart_chicken_2.webp" alt="chart chicken" />
      </div> */}
      <div className="trade-chicken">
        <img src="/assets/chicken/trade.gif" alt="trade chicken" />
      </div>
      <Social />
    </div>
  );
};

export default Chicken;
