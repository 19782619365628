import React, { useState, useEffect } from "react";
import Menu from "../components/Menu";
import RunBanner from "../components/RunBanner";
import About from "../components/About";
import Social from "../components/Social";
import "../App.css";
import RoadMap from "../components/RoadMap";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading-screen">
          <img src="assets/images/start_web.gif" alt="Loading..." />
        </div>
      ) : (
        <div className="container-home">
          <Menu />
          <div className="home-banner">
            <img
              className="banner"
              src="/assets/images/banner.webp"
              alt="banner home"
            ></img>
          </div>
          <RunBanner />
          <About />
          <RoadMap />
          <div className="home-galery">
            <p> NFT...Coming...Soon</p>
            <img
              className="gallery"
              src="/assets/images/gallery.webp"
              alt="banner home"
            ></img>
            <img
              className="gallery-m"
              src="/assets/images/gallery_m.webp"
              alt="banner home"
            ></img>
          </div>
          <Social />
        </div>
      )}
    </>
  );
};

export default Home;
