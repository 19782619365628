import React from "react";
import "../styles/roadmap.css";
import "../App.css";

const RoadMap = () => {
  return (
    <div className="container-page bg-roadmap">
      <div className="roadmap-item roadmap-left">
        <img
          src="/assets/images/roadmap1.png"
          alt="1"
          className="roadmap-image"
        />
        <div className="roadmap-content">
          <b>Phase 1: July - August, 2024 - The Crazy Chickens First Steps!</b>
          <div className="bg-content">
            <p>
              Alpha Launch: The first chickens take flight, ready to tap their
              way to madness.
            </p>
            <p>
              Explore the Crazy World: Tap, tap, tap, and discover the quirky
              wonders of the Crazy Chicken world.
            </p>
            <p>
              Crazy Chicken Connect: Gather the flock, sweep through the crypto
              world together, and invite friends – the more, the merrier!
            </p>
            <p>
              Bounty Hunters: Tons of rewards from exciting daily quests, the
              flock gets rich together.
            </p>
            <p>
              Chain Reaction: Connect with other communities to strengthen the
              flock
            </p>
          </div>
        </div>
      </div>
      <div className="roadmap-item roadmap-right">
        <div className="roadmap-content">
          <b> Phase 2: September, 2024 - Level Up, Crazy Chickens!</b>
          <div className="bg-content">
            <p>
              Beta Version: A bigger, crazier flock with more features to shake
              things up.
            </p>
            <p>
              More Crazy Games: Super chicken care, discover unique breeds,
              exciting games, and join crazy chicken events.
            </p>
            <p>
              Magical Cards: The flock competes for magical cards with surprise
              rewards.
            </p>
            <p>
              Connect by Flock: Chat, interact, and build the Crazy Chicken
              community by flock. Get super perks from your flock.
            </p>
            <p>
              Crazy Love: Love brings power to the flock, the crazy chickens get
              even crazier, and there are even more rewards.
            </p>
          </div>
        </div>
        <img
          src="/assets/images/roadmap2.png"
          alt="2"
          className="roadmap-image"
        />
      </div>
      <div className="roadmap-item roadmap-left">
        <img src="/assets/images/roadmap3.png" alt="3" />
        <div className="roadmap-content">
          <b>Phase 3: October, 2024 - Degen Chickens Take Over the World!</b>
          <div className="bg-content">
            <p>
              Global Launch: The Crazy Chickens officially land, taking over all
              territories, <br /> becoming a dominant force in the web3
              community.
            </p>
            <p>
              Crazy Chicken PFPs on the Market: Own fun and unique Crazy Chicken
              PFPs, trade rare chickens, and join crazy activities.
            </p>
            <p>
              Crazy Chicken Tournament: Fierce competition between chickens, win
              glory for your team.
            </p>
          </div>
        </div>
      </div>
      <div className="roadmap-item roadmap-right">
        <div className="roadmap-content">
          <b> Phase 4 Q4, 2024 - Degen Chickens Build a Crazy Empire!</b>
          <div className="bg-content">
            <p>
              Money, Money, Money: Get ready for the biggest airdrop event,
              money everywhere!
            </p>
            <p>
              Join Forces: Partner up to create a bigger Crazy Chicken world,
              not just chickens, but a society of all creatures.
            </p>
            <p>
              More Game More Fun: Continuous updates to keep the flock
              entertained, more special games, crazy parties for endless energy.
            </p>
            <p>
              Crazy Chicken Economy: Build a strong and prosperous Crazy Chicken
              economy, providing a steady flow of income for the flock.
            </p>
          </div>
        </div>
        <img
          src="/assets/images/roadmap4.png"
          alt="4"
          className="roadmap-image"
        />
      </div>
      <div className="roadmap-item roadmap-left">
        <img
          src="/assets/images/roadmap5.png"
          alt="5"
          className="roadmap-image"
        />
        <div className="roadmap-content">
          <b>Phase 5: Q1 2025 - Crazy Chickens Conquer the World!</b>
          <div className="bg-content mt-3">
            <p>
              Reach New Lands: Bring Crazy Chickens to every corner of the
              world.
            </p>
            <p>
              Global Crazy Chicken Events: Host huge events, attracting everyone
              to join.
            </p>
            <p>
              Never Stop Creating: Constant innovation to keep Crazy Chickens on
              top, with more special events to be revealed soon.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
