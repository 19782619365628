import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/menu.css";
import logo from "../assets/images/logo.webp";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu-container">
      <b className="text-logo">
        <img src={logo} alt="logo-degen" />
      </b>
      <div className="menu-hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`menu-tabs ${isOpen ? "open" : ""}`}>
        <NavLink exact to="/" activeClassName="active">
          ChickenDegen
        </NavLink>
        <NavLink to="/CKDAirdrop" activeClassName="active">
          $CKDAirdrop
        </NavLink>
        <NavLink to="/story" activeClassName="active">
          Story
        </NavLink>
        <NavLink to="/white-paper" activeClassName="active">
          WhitePaper
        </NavLink>
        {/* <NavLink to="/Free-NFT" activeClassName="active">
          NFT FreeMint Register
        </NavLink> */}
      </div>
    </div>
  );
};

export default Menu;
