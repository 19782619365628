import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Buffer } from "buffer";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import HomeGame from "./pages/HomeGame";
import Story from "./pages/Story";
import Chicken from "./pages/Chicken";
import WhitePaper from "./pages/WhitePaper";
// import NFT from "./pages/NFT";

window.Buffer = window.Buffer || Buffer;

export default function App() {
  return (
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/abc" element={<HomeGame />} />
          <Route path="/CKDAirdrop" element={<Chicken />} />
          <Route path="/story" element={<Story />} />
          <Route path="/white-paper" element={<WhitePaper />} />
          {/* <Route path="/Free-NFT" element={<NFT />} /> */}
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
