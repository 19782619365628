import React from "react";
import "../styles/about.css";
import "../App.css";

const About = () => {
  return (
    <div className="container-page bg-about">
      <div className="about-page">
        <img src="/assets/images/about.gif" alt="gif about" />
        <div className="content-about">
          <b>About The Game</b>
          <br />
          <br />
          <p>
            Welcome, Degens! Your journey will start from here. Are you ready?
          </p>
          <p>
            Introducing Chicken Degen, the tap-to-earn game where your tapping
            skills turn into real $CKD tokens!
          </p>
          <p>
            Tap To Earn; Welcome Rewards; Referral System; Daily Rewards;
            Mission; Mine; Make Love; Egg Bucket; Clan; Mystery Daily Cards;
            Function Cards; Character Equipment;...
          </p>
          <p>
            But wait! It's not just that!
            <br />
            Chicken Degen is a Community-driven Brand with a Gaming Ecosystem
            you won't find anywhere else!
          </p>
          <p>
            100% Utility <br />
            100% Good Vibe <br />
            100% Community-driven
          </p>
          <p>So, what are you waiting for?</p>
          <p>
            Play Chicken Degen and be ready for all the good vibes, funny arts,
            Free NFTs and an ecosystem of game hubs that will be released soon.
            Being active is the key! You won't wanna miss what we are cooking!
          </p>
          <p>
            Especially! The massive community $CKD airdrop at the token listing!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
