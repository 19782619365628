import React from "react";
import "../styles/socials.css";
import "../App.css";

const Social = () => {
  return (
    <div className="container-page social-page">
      <div className="social-text">
        <img src="assets/images/social_text.webp" alt="social chiken"></img>
      </div>
      <div className="social-header">
        <p>Join the Chicken DEGEN community</p>
        <div className="social-links">
          <a
            href="https://x.com/Chickendegen_io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/twitter.png" alt="twitter"></img>
          </a>
          <a
            href="https://t.me/chickendegen"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/telegram.png" alt="telegram"></img>
          </a>
          <a
            href="https://t.me/chickendegen_bot"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/game.png" alt="play game"></img>
          </a>
        </div>
      </div>

      <div className="social-content">
        <img src="/assets/images/socials.gif" alt="social"></img>
        <div className="text-animation">Chicken DEGEN</div>
      </div>
    </div>
  );
};

export default Social;
